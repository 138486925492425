import { render, staticRenderFns } from "./2021-04-01-brew-error-formulae-require-at-least-a-url.md?vue&type=template&id=3bda34a0&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports